import { getDeviceId, getDeviceDua } from "./device";
import { sendGetRequest, sendPostRequest, sendPostRequestFromServer } from "./sendRequest";

/* axios POST request not working with nextjs api somehow.
import axios from "axios";

export async function authenticateUser2(platform, authData) {
  return await axios.post("/api/authenticate", {
    platform: platform,
    data: authData,
  });
}
*/

export async function updateUser(userId, userBlob, authData, expVersion) {
  const deviceId = getDeviceId();
  const deviceDua = getDeviceDua();
  try {
    return await sendPostRequest("/api/users/update", {
      userId,
      userBlob,
      expVersion,
      deviceId: deviceId,
      deviceDua: deviceDua,
      authData,
    });
  } catch (error) {
    return error.error || "Update user failed.";
  }
}

export async function fetchUser(userId, authData, fallbackFunc, limitVisibility = false) {
  try {
    return await sendPostRequest(
      "/api/users/get",
      {
        userId,
        limitVisibility,
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return "Fetch user info failed.";
  }
}

export async function deleteUser(userId, authData) {
  try {
    return await sendPostRequest("/api/users/delete_user", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "delete user failed";
  }
}

export async function fetchTenantInfo(fetchTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get", {
      fetchTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function fetchTenantFeatures(featureTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get_features", {
      featureTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return {};
  }
}

export async function updateTenantInfo(tenantBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/update", {
      tenantBlob,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error || "Update tenant failure";
  }
}

export async function deleteTenant(deleteTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/delete", {
      deleteTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function slackAuthWithLoginAccess(userId, authData) {
  try {
    return await sendPostRequest(
      "/api/oauth/slack_auth_login_access",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      { credentials: "include" }
    );
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
}

export async function login(values, recaptchaResponse) {
  const deviceId = getDeviceId();
  const deviceDua = getDeviceDua();
  try {
    return await sendPostRequest("/api/users/login", {
      email: values.email,
      password: values.password,
      recaptchaResponse,
      deviceId: deviceId,
      deviceDua: deviceDua,
    });
  } catch (error) {
    return error;
  }
}

export async function logout(userId, authData) {
  try {
    return await sendPostRequest("/api/users/logout", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update user failed.";
  }
}

export async function signup(
  email,
  password,
  defaultLanguage,
  recaptchaResponse,
  userBlob,
  referralCode,
  source
) {
  try {
    const deviceId = getDeviceId();
    const deviceDua = getDeviceDua();
    return await sendPostRequest("/api/users/signup", {
      email,
      password,
      defaultLanguage,
      recaptchaResponse,
      userBlob,
      referralCode,
      source,
      deviceId: deviceId,
      deviceDua: deviceDua,
    });
  } catch (error) {
    return error.errMsg || "signup failed";
  }
}

export async function resetPassword(email, recaptchaResponse, authData) {
  try {
    return await sendPostRequest("/api/users/reset_password", {
      email,
      recaptchaResponse,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.errMsg ? JSON.parse(error.errMsg).description : error.error;
  }
}

export async function changePassword(userId, newPassword, authData) {
  try {
    return await sendPostRequest("/api/users/update_password", {
      userId,
      newPassword,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Change password failed.";
  }
}

export async function generateTotpSecret(email) {
  try {
    return await sendPostRequest("/api/users/totp_generate_secret", {
      email,
    });
  } catch (error) {
    return error.error;
  }
}

export async function validateSignupTotp(qrSecret, token) {
  try {
    return await sendPostRequest("/api/users/totp_signup_verify", {
      qrSecret,
      token,
    });
  } catch (error) {
    return error.error;
  }
}

export async function validateLoginTotp(token, code, email, authData) {
  try {
    return await sendPostRequest("/api/users/totp_login_verify", {
      token,
      code,
      email,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error;
  }
}

export async function signupValidation(email, referralCode) {
  try {
    const resp = await sendPostRequest("/api/users/signup_validation", {
      email,
      referralCode,
    });
    return resp;
  } catch (error) {
    return error || "Signup validation failed.";
  }
}

export async function sendVerificationEmail(email, authData) {
  try {
    return await sendPostRequest("/api/users/send_email_verification", {
      email,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return "Email verification failed.";
  }
}

export async function fetchProjects(userId, authData, filterGroup) {
  try {
    return await sendPostRequest("/api/projects/get_all", {
      userId,
      filterGroup,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function fetchProjectsByUserId(userId, authData, queryUserId) {
  try {
    return await sendPostRequest("/api/projects/get_all_by_userId", {
      userId,
      queryUserId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function fetchProjectsWithLastComment(userId, authData, filterGroup) {
  try {
    return await sendPostRequest("/api/projects/get_all_for_admin_page", {
      userId,
      filterGroup,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function fetchBriefProjectsByTenantId(userId, authData, briefTenantId) {
  try {
    return await sendPostRequest("/api/projects/get_brief_by_tenant_id", {
      userId,
      authData,
      briefTenantId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function fetchAllBriefProjectsForCertiK(userId, authData) {
  try {
    return await sendPostRequest("/api/projects/get_all_brief_projects_for_certik", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function fetch7DCompletedSignedOffProjects(userId, tenantId, authData) {
  try {
    return await sendPostRequest("/api/projects/get_projects_last_7D_signoff_completed", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function postNewAudit(formData, userId, authData) {
  try {
    return await sendPostRequest("/api/projects/new", {
      formData,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (err) {
    console.log(err);
    throw new Error("Failed to post new audit");
  }
}

export async function inviteCollaborator(
  inviteeEmail,
  inviteeRole,
  inviteeCertikTeamId,
  inviterId,
  authData,
  toTenantId = null
) {
  try {
    return await sendPostRequest("api/users/invite_collaborator", {
      inviteeEmail,
      inviteeRole,
      inviteeCertikTeamId,
      inviterId,
      toTenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Invite collaborator failed";
  }
}

export async function updateInvitation(inviteeEmail, inviteeRole, certikTeamId, authData) {
  try {
    return await sendPostRequest("api/referrals/update", {
      inviteeEmail,
      inviteeRole,
      certikTeamId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update invitation failed";
  }
}

export async function resendInvitation(inviteeEmail, inviterId, authData) {
  try {
    return await sendPostRequest("api/users/resend_invitation", {
      inviteeEmail,
      inviterId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Invite collaborator failed";
  }
}

export async function getInvitationForFrontend(referralCode) {
  try {
    return await sendPostRequest("/api/referrals/get", {
      referralCode,
    });
  } catch (error) {
    return error.error || "Get invitation failed";
  }
}

export async function getInvitation(referralCode) {
  // for server-side use
  const requestUrlPrefix =
    process.env.NEXT_PUBLIC_ENV === "staging"
      ? "https://" + process.env.NEXT_PUBLIC_VERCEL_URL
      : process.env.NEXT_PUBLIC_DOMAIN;
  try {
    return await sendPostRequestFromServer(
      requestUrlPrefix + "/api/referrals/get",
      {
        referralCode,
      },
      undefined,
      undefined,
      false
    );
  } catch (error) {
    console.log(JSON.stringify(error));
    return JSON.stringify(error) || "Get invitation failed";
  }
}

export async function deleteInvitation(inviteeEmail, inviterId, authData) {
  try {
    return await sendPostRequest("/api/referrals/delete", {
      inviteeEmail,
      inviterId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get invitation failed";
  }
}

export async function getCollaboratorInfo(collaboratorId, authData) {
  try {
    return await sendPostRequest("/api/users/get_collaborator_info", {
      collaboratorId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get collaborator info failed";
  }
}

export async function getCollaborators(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/users/get_collaborators",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get collaborator failed";
  }
}

export async function getPendingCollaboratorsByTenantId(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/users/get_pending_collaborators_by_tenantId",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get pending collaborator by tenant failed";
  }
}

export async function getMultiTypeCollaborates(userId, tenantIdList, authData) {
  try {
    return await sendPostRequest("/api/users/get_multi_type_collaborates", {
      userId,
      tenantIdList,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get collaborators info failed";
  }
}

export async function getAllDecoratedUsers(userId, authData) {
  try {
    return await sendPostRequest("/api/users/get_all_decorated_users", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get decorated users failed";
  }
}

export async function getPendingCollaborators(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/users/get_pending_collaborators",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get pending collaborators failed";
  }
}

export async function getNotificationList(userId, authData, type, limit, paginationTimestamp) {
  try {
    return await sendPostRequest("/api/notifications/get_all", {
      userId,
      deviceId: getDeviceId(),
      authData,
      type,
      limit,
      paginationTimestamp,
    });
  } catch (error) {
    return [];
  }
}

export async function getProjectSecurityScoreDetailsByProjectId(
  userId,
  tenantId,
  authData,
  projectId
) {
  try {
    return await sendPostRequest(
      "/api/projects/security-score/get_project_security_score_details",
      {
        projectId,
        tenantId,
        userId,
        authData,
        deviceId: getDeviceId(),
      }
    );
  } catch (error) {
    console.log(error);
    throw new Error(error.errMsg);
  }
}

export async function securityScoreSignOff(
  pendingPeriod,
  projectId,
  userId,
  authData,
  actionDebug = ""
) {
  try {
    return await sendPostRequest("/api/projects/security-score/project_security_score_sign_off", {
      pendingPeriod,
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
      actionDebug,
    });
  } catch (error) {
    console.log(error);
    throw new Error(error.errMsg);
  }
}

export async function getUserProjectInfo(userId, projectId, authData) {
  try {
    return await sendPostRequest("/api/projects/fetch_user_project", {
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    throw new Error(error.errMsg);
  }
}

export async function getUserProjectInfoForServer(userId, projectId, authData) {
  const requestUrlPrefix =
    process.env.NEXT_PUBLIC_ENV == "development"
      ? process.env.NEXT_PUBLIC_DOMAIN
      : "https://" + process.env.NEXT_PUBLIC_VERCEL_URL;
  try {
    return await sendPostRequestFromServer(
      // process.env.NEXT_PUBLIC_DOMAIN + "/api/projects/fetch_user_project",
      requestUrlPrefix + "/api/projects/fetch_user_project",
      {
        userId,
        projectId,
        deviceId: authData.isForTwitterCard ? "" : getDeviceId(),
        authData,
      },
      undefined,
      undefined,
      false
    );
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getProjectInfoForMarketplace(projectId, userId, tenantId, authData) {
  try {
    return await sendPostRequest("/api/projects/get_project_for_marketplace", {
      projectId,
      userId,
      tenantId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    throw new Error(error.errMsg);
  }
}

export async function getProjectInfoForShareLink(userId, projectId, reportType, authData) {
  try {
    return await sendPostRequest("/api/projects/get_project_for_share_link", {
      userId,
      projectId,
      deviceId: authData.linkId ? "" : getDeviceId(),
      authData,
      reportType,
    });
  } catch (error) {
    return null;
  }
}

export async function deleteNotification(userId, createdAt, purge = false, isSoftArchive = false) {
  try {
    await sendPostRequest("/api/notifications/delete", {
      userId,
      createdAt,
      purge,
      isSoftArchive,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateProject(userId, projectId, projectInfo, authData) {
  try {
    const resp = await sendPostRequest("/api/projects/update", {
      userId,
      projectId,
      projectInfo,
      deviceId: getDeviceId(),
      authData,
    });
    if (resp.result === "success") {
      return true;
    } else {
      return resp;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = error.error;
    if (error.errMsg) {
      errorMessage += ` - ${error.errMsg}`;
    }
    return errorMessage;
  }
}

export async function getProjectReportFromVersion(
  userId,
  authData,
  projectInfo,
  acceleratorProjectId,
  reportVersion
) {
  try {
    const resp = await sendPostRequest("/api/projects/get_project_report_from_version", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectInfo,
      acceleratorProjectId,
      reportVersion,
    });
    return resp;
  } catch (error) {
    console.log(error);
    let errorMessage = error.error;
    if (error.errMsg) {
      errorMessage += ` - ${error.errMsg}`;
    }
    return errorMessage;
  }
}

export async function updateProjectCommentCheck(userId, projectId, projectInfo, authData) {
  try {
    await sendPostRequest("/api/projects/update_comment_check", {
      userId,
      projectId,
      projectInfo,
      deviceId: getDeviceId(),
      authData,
    });
    return true;
  } catch (error) {
    console.log(error);
    return error.error;
  }
}

export async function fetchProjectCountByStatus(userId, filterGroup, page, authData) {
  try {
    return await sendPostRequest("/api/projects/get_project_count_by_status", {
      userId,
      filterGroup,
      page,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function changeCollaboratorRole(
  collaboratorId,
  collaboratorRole,
  expVersion,
  authData,
  certikTeamId
) {
  try {
    return await sendPostRequest("/api/users/change_collaborator_role", {
      collaboratorId,
      collaboratorRole,
      certikTeamId,
      expVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's role failed";
  }
}

export async function changeUserStatus(userId, expVersion, deactivated, authData) {
  try {
    return await sendPostRequest("/api/users/change_user_activation_status", {
      userId,
      expVersion,
      deactivated,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's status failed";
  }
}

export async function updateUserAccess(collaboratorId, collaboratorBlob, expVersion, authData) {
  try {
    return await sendPostRequest("/api/users/update_user_access", {
      collaboratorId,
      collaboratorBlob,
      expVersion,
      authData,
      deviceId: getDeviceId(),
      deviceDua: getDeviceDua(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's role failed";
  }
}

export async function updateUserAdmin(userId, userBlob, expVersion, authData) {
  try {
    return await sendPostRequest("/api/users/update_user_admin", {
      userId,
      userBlob,
      expVersion,
      authData,
      deviceId: getDeviceId(),
      deviceDua: getDeviceDua(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's role failed";
  }
}

export async function getLeaderboardInfo(userId, leaderboardId, authData) {
  try {
    return await sendPostRequest(`/api/projects/get_leaderboard_info`, {
      userId,
      leaderboardId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return {};
  }
}

export async function fetchHistoryList(projectId, userId, authData, limit, paginationTimestamp) {
  try {
    return await sendPostRequest("/api/project_histories/fetch_project_history", {
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
      limit,
      paginationTimestamp,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchCommentList(projectId, userId, authData, limit, paginationTimestamp) {
  try {
    return await sendPostRequest("/api/project_comments/get_all", {
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
      limit,
      paginationTimestamp,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function postNewComment(
  projectId,
  userId,
  userName,
  authData,
  text,
  images,
  emailList,
  emailMeta,
  projectType
) {
  try {
    return await sendPostRequest("/api/project_comments/new", {
      userId,
      userName,
      projectId,
      deviceId: getDeviceId(),
      authData,
      text,
      images,
      emailList,
      emailMeta,
      projectType,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function uploadImage(userId, fileMeta, fileData, expVersion, authData) {
  try {
    return await sendPostRequest("/api/users/upload_avatar", {
      userId,
      fileMeta,
      fileData,
      expVersion,
      authData,
      deviceId: getDeviceId(),
      deviceDua: getDeviceDua(),
    });
  } catch (error) {
    // TODO: add log
    return error.error || "Update avatar failure";
  }
}

export async function getTenants(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/tenants/get_all",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get tenants failure";
  }
}

export async function createTenant(userId, tenantBlob, tenantAdmin, authData) {
  try {
    return await sendPostRequest("/api/tenants/new", {
      userId,
      tenantBlob,
      tenantAdmin,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error || "Create tenant failure";
  }
}

export async function fetchS3PresignedUrl(
  userId,
  projectTenantId,
  authData,
  keyList,
  command,
  fileType
) {
  try {
    const { urlList } = await sendPostRequest("/api/fetch_s3_presigned_url", {
      userId,
      projectTenantId,
      authData,
      deviceId: getDeviceId(),
      keyList,
      command,
      fileType,
    });
    return urlList;
  } catch (error) {
    return null;
  }
}

export async function deleteProject(userId, authData, projectIdList) {
  try {
    await sendPostRequest("/api/projects/delete", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectIdList,
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function getCertikTeams(userId, authData) {
  try {
    return await sendPostRequest("/api/certik_teams/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.errMsg || "Get certik teams failure";
  }
}

export async function getAllCertikAssigneesByRole(role, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/get_all", {
      role,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getAssigneesByProjectId(projectId, role, userId, authData, forMarketplace) {
  try {
    return await sendPostRequest("/api/assignee/get_by_project_id", {
      projectId,
      role,
      userId,
      authData,
      deviceId: getDeviceId(),
      forMarketplace,
    });
  } catch (error) {
    return null;
  }
}

export async function getAssigneeProjectIdsByType(assigneeId, type, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/get_project_ids_by_type", {
      assigneeId,
      type,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function createCertikAssigneeAccess(assigneeId, projectId, type, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/create_access", {
      assigneeId,
      projectId,
      type,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function deleteCertikAssigneeAccess(assigneeId, projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/delete_access", {
      assigneeId,
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function fetchProgressList(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/project_histories/fetch_project_progress", {
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchFindingCommentList(
  findingCombinationKey,
  projectId,
  userId,
  authData,
  limit,
  paginationTimestamp,
  pageAccess,
  mergeAcrossReports = false
) {
  try {
    return await sendPostRequest("/api/finding_comments/get_all", {
      findingCombinationKey,
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
      limit,
      paginationTimestamp,
      pageAccess,
      mergeAcrossReports,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function certikDelFinding({
  id,
  projectId,
  userId,
  authData,
  findingId,
  deleteAcrossReports = false,
}) {
  try {
    return await sendPostRequest("/api/incremental_findings_report/delete_finding", {
      id,
      projectId,
      authData,
      deviceId: getDeviceId(),
      userId,
      findingId,
      deleteAcrossReports,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

// TODO: better merge getReportCommentCount and getReportCommentCountOnServerSide into one func
export async function getReportCommentCount(reportId, projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/finding_comments/get_count", {
      reportId,
      userId,
      projectId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getReportCommentCountOnServerSide(reportId, userId, projectId, authData) {
  const requestUrlPrefix =
    process.env.NEXT_PUBLIC_ENV === "staging"
      ? "https://" + process.env.NEXT_PUBLIC_VERCEL_URL
      : process.env.NEXT_PUBLIC_DOMAIN;
  try {
    return await sendPostRequestFromServer(
      requestUrlPrefix + "/api/finding_comments/get_count",
      {
        reportId,
        userId,
        projectId,
        authData,
        deviceId: "",
      },
      undefined,
      undefined,
      false
    );
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function postNewFindingComment(
  findingCombinationKey,
  projectId,
  reportId,
  userId,
  authData,
  text,
  images,
  emailList,
  emailMeta,
  acceleratorVersion,
  acceleratorObjectId,
  findingId,
  pageAccess = "audit"
) {
  try {
    return await sendPostRequest("/api/finding_comments/new", {
      findingCombinationKey,
      userId,
      projectId,
      reportId,
      deviceId: getDeviceId(),
      authData,
      text,
      images,
      emailList,
      emailMeta,
      acceleratorVersion,
      findingId,
      acceleratorObjectId,
      pageAccess,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function postMeetingRequest(
  findingCombinationKey,
  projectId,
  reportId,
  userId,
  authData,
  blob,
  emailMeta,
  acceleratorVersion,
  findingId,
  pageAccess = "audit"
) {
  try {
    return await sendPostRequest("/api/projects/new_request_meeting", {
      findingCombinationKey,
      userId,
      projectId,
      reportId,
      deviceId: getDeviceId(),
      authData,
      blob,
      emailMeta,
      acceleratorVersion,
      findingId,
      pageAccess,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMeetingRequest(
  projectId,
  reportId,
  userId,
  authData,
  pageAccess = "audit"
) {
  try {
    return await sendPostRequest("/api/projects/get_request_meeting", {
      projectId,
      reportId,
      userId,
      deviceId: getDeviceId(),
      authData,
      pageAccess,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCollaboratorsForMention(
  projectId,
  projectTenantId,
  userId,
  authData,
  forReport,
  projectTenantIds
) {
  try {
    return await sendPostRequest("/api/users/get_collaborators_for_mention", {
      projectId,
      projectTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
      forReport,
      projectTenantIds,
    });
  } catch (error) {
    return error.error || "Get pending collaborators failed";
  }
}

export async function verifyCoinGeckoLink(coinGeckoLink) {
  try {
    return await sendPostRequest("/api/verify_coin_gecko_link", {
      coinGeckoLink,
    });
  } catch (error) {
    return error;
  }
}

export async function verifyCoinMarketCapLink(coinMarketCapLink) {
  try {
    return await sendPostRequest("/api/verify_coin_marketcap_link", {
      coinMarketCapLink,
    });
  } catch (error) {
    return error;
  }
}

export async function fetchTokenPrice(tokenId) {
  try {
    return await sendPostRequest("/api/fetch_token_price", {
      tokenId,
    });
  } catch (error) {
    return error;
  }
}

export async function postNewSkynetProject(
  projectName,
  contractAddressUrlList,
  telegram,
  twitter,
  website,
  github,
  tokenTicker,
  coinMarketCapLink,
  coinGeckoLink,
  tagList,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/skynets/new", {
      projectName,
      contractAddressUrlList,
      telegram,
      twitter,
      website,
      github,
      tokenTicker,
      coinMarketCapLink,
      coinGeckoLink,
      tagList,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return error.errMsg || "Failed to post new skynet project";
  }
}

export async function fetchSkynetSignal(id, userId, authData) {
  try {
    return await sendPostRequest("/api/skynets/signal/get", {
      id,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return { error: error.error };
  }
}

export async function fetchSkynetProject(id, userId, authData) {
  try {
    return await sendPostRequest("/api/skynets/get", {
      id,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return { error: error.error };
  }
}

export async function fetchAllDecoratedSkynetProjectsWithVMS(
  userId,
  authData,
  bypassCache = false,
  isSidebarMode = false
) {
  try {
    return await sendPostRequest("/api/skynets/vms/get_all_decorated", {
      userId,
      authData,
      deviceId: getDeviceId(),
      bypassCache,
      isSidebarMode,
    });
  } catch (error) {
    return [];
  }
}

export async function fetchSkynetProjectWithVMS(id, userId, authData) {
  try {
    return await sendPostRequest("/api/skynets/vms/get", {
      id,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return { error: error.error };
  }
}

export async function fetchAllSkynetProjectsForSideBar(userId, authData) {
  try {
    return await sendPostRequest("/api/skynets/get_all_for_sidebar", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function fetchAllSkynetProjects(userId, authData) {
  try {
    return await sendPostRequest("/api/skynets/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function updateSkynetProject(
  id,
  projectTenantId,
  skynetBlob,
  userId,
  authData,
  expVersion
) {
  try {
    return await sendPostRequest("/api/skynets/update", {
      id,
      projectTenantId,
      skynetBlob,
      userId,
      authData,
      expVersion,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update skynet project failed.";
  }
}

export async function deleteSkynetProjects(userId, authData, projectList) {
  try {
    await sendPostRequest("/api/skynets/delete", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectList,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getProjectFromLuProjects(userId, authData, projectId) {
  try {
    return await sendPostRequest(`/api/skynets/${projectId}/get_project_detail`, {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return {};
  }
}

export async function postNewTask(category, projectId, taskBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/task/new", {
      category,
      projectId,
      taskBlob,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return error.errMsg || "Failed to post new task";
  }
}

export async function getTask(projectId, category, userId, authData) {
  try {
    return await sendPostRequest("/api/task/get", {
      projectId,
      category,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return null;
  }
}

export async function getTaskById(id, userId, authData) {
  try {
    return await sendPostRequest("/api/task/get_by_id", {
      id,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return null;
  }
}

// userId, authData, deviceId, tenantId, nftCollectionId
export async function getTaskByTenantIdAndNftCollectionId(
  userId,
  authData,
  tenantId,
  nftCollectionId
) {
  try {
    return await sendPostRequest("/api/task/get_by_nftCollectionId_and_tenantId", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      nftCollectionId,
    });
  } catch (error) {
    return null;
  }
}

export async function getAllTasks(userId, authData) {
  try {
    return await sendPostRequest("/api/task/get_all", {
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return null;
  }
}

export async function updateTask(id, taskTenantId, taskBlob, userId, authData, expVersion) {
  try {
    return await sendPostRequest("/api/task/update", {
      id,
      taskTenantId,
      taskBlob,
      expVersion,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return error.errMsg || "Failed to update task";
  }
}

export async function deleteTasks(userId, authData, tasks) {
  try {
    await sendPostRequest("/api/task/delete", {
      userId,
      authData,
      tasks,
      deviceId: getDeviceId(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getHackScan(projectId, userId, authData) {
  try {
    return await sendPostRequest(`/api/skynets/${projectId}/get_hack_scan`, {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return {};
  }
}

export async function updateTenantFeatureAccess({
  featureTenantId,
  featureId,
  enabled,
  expVersion,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/tenants/update_feature", {
      userId,
      authData,
      deviceId: getDeviceId(),
      featureTenantId,
      featureId,
      enabled,
      expVersion,
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getDashboardData(userId, authData) {
  try {
    return await sendPostRequest("/api/dashboard/get_dashboard_data", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function deleteAlert(createdAt, taskId, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/delete", {
      createdAt,
      taskId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getAlertsByTenant(alertTenantId, endTimestamp, limit, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/get_by_tenant", {
      alertTenantId,
      endTimestamp,
      limit,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getAllAlerts(endTimestamp, limit, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/get_all", {
      endTimestamp,
      limit,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getFlashloanPredictions(
  startTimestamp,
  endTimestamp,
  platform,
  limit,
  userId,
  authData,
  alertName,
  provider,
  txhash,
  minLoanAmount,
  maxLoanAmount,
  minProfit,
  maxProfit,
  criticality,
  likelihood
) {
  try {
    return await sendPostRequest("/api/flashloan/get_alert", {
      startTimestamp,
      endTimestamp,
      platform,
      limit,
      alertName,
      provider,
      txhash,
      minLoanAmount,
      maxLoanAmount,
      minProfit,
      maxProfit,
      criticality,
      likelihood,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getAllFlashLoans(
  startTimestamp,
  endTimestamp,
  limit,
  platform,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/flashloan/get_all", {
      startTimestamp,
      endTimestamp,
      limit,
      platform,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getTenantNameMap(userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get_tenant_name_map", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getFlashloan(transactionHash, userId, authData) {
  try {
    return await sendPostRequest("/api/flashloan/get", {
      transactionHash,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function updateFlashloan(
  transactionHash,
  flashloanBlob,
  userId,
  authData,
  expVersion
) {
  try {
    return await sendPostRequest("/api/flashloan/update", {
      transactionHash,
      flashloanBlob,
      userId,
      authData,
      expVersion,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

// -------Shield------ //
export async function postNewShieldProject(
  projectName,
  tokenTicker,
  certikAddress,
  contractAddressUrlList,
  coveragePeriods,
  contractCoverage,
  someFields,
  termsAndConditions,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/shield/new", {
      projectName,
      tokenTicker,
      certikAddress,
      contractAddressUrlList,
      coveragePeriods,
      contractCoverage,
      someFields,
      termsAndConditions,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return error.errMsg || "Failed to post new shield project";
  }
}

export async function getShieldProject(id, userId, authData) {
  try {
    return await sendPostRequest("/api/shield/get", {
      id,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return { error: error.error };
  }
}

export async function getAllShieldProjects(userId, authData) {
  try {
    return await sendPostRequest("/api/shield/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function deleteShieldProjects(userId, authData, projectList) {
  try {
    await sendPostRequest("/api/shield/delete", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectList,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateShieldProject(
  id,
  projectTenantId,
  shieldBlob,
  userId,
  authData,
  expVersion
) {
  try {
    return await sendPostRequest("/api/shield/update", {
      id,
      projectTenantId,
      shieldBlob,
      userId,
      authData,
      expVersion,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update shield project failed.";
  }
}

export async function NotifyAssigneeReportReview(
  projectId,
  reportFileName,
  commentCount,
  userId,
  authData,
  actionType
) {
  try {
    return await sendPostRequest("/api/assignee/notify_report_review", {
      projectId,
      reportFileName,
      commentCount,
      userId,
      authData,
      deviceId: getDeviceId(),
      actionType,
    });
  } catch (error) {
    return error;
  }
}

export async function notifyPdfDownload(projectId, reportFileName, reportId, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/notify_pdf_download", {
      projectId,
      reportFileName,
      reportId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error;
  }
}

export async function getAllFlashloanAccounts(userId, authData) {
  try {
    return await sendPostRequest("/api/flashloan/get_all_accounts", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getFlashloanSummary(
  startTimestamp,
  endTimestamp,
  platform,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/flashloan/summary", {
      startTimestamp,
      endTimestamp,
      platform,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return {};
  }
}

export async function getConfig(keyName) {
  try {
    return await sendPostRequest("/api/configs/get", {
      keyName,
      env: process.env.NEXT_PUBLIC_ENV,
    });
  } catch (error) {
    return {};
  }
}

export async function getAllPublicConfigs() {
  try {
    return await sendPostRequest(
      "/api/configs/get_all_public",
      {
        env: process.env.NEXT_PUBLIC_ENV,
      },
      undefined,
      undefined,
      undefined,
      10000
    );
  } catch (error) {
    return {};
  }
}

// for server-side fetching
export async function getConfigOnServerSide(keyName) {
  const requestUrlPrefix =
    process.env.NEXT_PUBLIC_ENV === "staging"
      ? "https://" + process.env.NEXT_PUBLIC_VERCEL_URL
      : process.env.NEXT_PUBLIC_DOMAIN;
  try {
    return await sendPostRequestFromServer(
      requestUrlPrefix + "/api/configs/get",
      {
        keyName,
        env: process.env.NEXT_PUBLIC_ENV,
      },
      undefined,
      undefined,
      false
    );
  } catch (error) {
    return {};
  }
}

export async function createShareLink(
  projectId,
  reportFileName,
  toUserEmail,
  userId,
  authData,
  reportType = null,
  readOnly = false
) {
  try {
    return await sendPostRequest("/api/report_share/new", {
      projectId,
      reportFileName,
      toUserEmail,
      userId,
      authData,
      reportType,
      deviceId: getDeviceId(),
      readOnly,
    });
  } catch (error) {
    return {};
  }
}

export async function createShareLinkByProjectId(
  projectId,
  toUserEmail,
  userId,
  authData,
  reportType = null,
  readOnly = false
) {
  try {
    return await sendPostRequest("/api/report_share/new_by_projectId", {
      projectId,
      toUserEmail,
      userId,
      authData,
      reportType,
      deviceId: getDeviceId(),
      readOnly,
    });
  } catch (error) {
    return {};
  }
}

export async function getShareLink(linkId) {
  try {
    return await sendPostRequest("/api/report_share/get", {
      linkId,
    });
  } catch (error) {
    return null;
  }
}

export async function getShareLinksByReport(reportFileName, projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/report_share/get_by_report", {
      reportFileName,
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return [];
  }
}

export async function getShareLinksByProjectId(projectId, userId, authData, reportType) {
  try {
    return await sendPostRequest("/api/report_share/get_by_projectId", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
      reportType,
    });
  } catch (error) {
    return [];
  }
}

export async function deleteShareLink(linkId, userId, authData) {
  try {
    return await sendPostRequest("/api/report_share/delete", {
      linkId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return {};
  }
}

export async function signOffReport(
  userId,
  authData,
  reportId,
  projectId,
  projectVersion,
  clientRequestToPublish,
  nextStatus
) {
  try {
    const resp = await sendPostRequest("/api/reports/signoff", {
      reportId,
      projectId,
      projectVersion,
      clientRequestToPublish,
      userId,
      authData,
      deviceId: getDeviceId(),
      nextStatus,
    });
    if (resp.result === "success") {
      return true;
    } else {
      return resp;
    }
  } catch (error) {
    return "Failed to signoff report.";
  }
}

export async function createJiraTicket(userId, authData, projectId, actionType) {
  try {
    return await sendPostRequest("/api/projects/create_jira_ticket", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
      actionType,
    });
  } catch (error) {
    return null;
  }
}

export async function confirmReport(userId, authData, reportId, projectId, confirmed) {
  try {
    return await sendPostRequest("/api/reports/confirm", {
      reportId,
      projectId,
      userId,
      authData,
      confirmed,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function showSignOff(userId, authData, reportId, projectId, showSignoff) {
  try {
    return await sendPostRequest("/api/reports/showSignOff", {
      reportId,
      projectId,
      userId,
      authData,
      showSignoff,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function updateReport(userId, authData, reportId, projectId, reportInfo) {
  try {
    const resp = await sendPostRequest("/api/reports/update", {
      reportId,
      projectId,
      userId,
      authData,
      reportInfo,
      deviceId: getDeviceId(),
    });
    if (resp.result === "success") {
      return true;
    } else {
      return resp;
    }
  } catch (error) {
    console.log(error);
    let errorMessage = error.error;
    if (error.errMsg) {
      errorMessage += ` - ${error.errMsg}`;
    }
    return errorMessage;
  }
}

export async function getAllHackEvents(startTimestamp, endTimestamp, platform, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/hack_events/get_all", {
      startTimestamp,
      endTimestamp,
      platform,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAllHackEventsByDay(
  startTimestamp,
  endTimestamp,
  platform,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/alerts/hack_events/get_day_group", {
      startTimestamp,
      endTimestamp,
      platform,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getHackEventsByIds(ids, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/hack_events/get", {
      ids,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getHackEventsBylimit(
  endTimestamp,
  startTimestampBound,
  platform,
  lostAmount,
  limit,
  userId,
  authData,
  alertName = null,
  attackMethod = null,
  note = null
) {
  try {
    return await sendPostRequest("/api/alerts/hack_events/get_by_limit", {
      endTimestamp,
      startTimestampBound,
      platform,
      lostAmount,
      limit,
      alertName,
      attackMethod,
      note,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function updateHackEvent(item, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/hack_events/update", {
      item,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAllPriceSlippage(
  startTimestamp,
  endTimestamp,
  platform,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/alerts/price_slippage/get_all", {
      startTimestamp,
      endTimestamp,
      platform,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAllPriceSlippageByDay(
  startTimestamp,
  endTimestamp,
  platform,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/alerts/price_slippage/get_day_group", {
      startTimestamp,
      endTimestamp,
      platform,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAllPriceSlippageByChain(
  startTimestamp,
  endTimestamp,
  platform,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/alerts/price_slippage/get_chain_group", {
      startTimestamp,
      endTimestamp,
      platform,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getPriceSlippageBylimit(
  endTimestamp,
  startTimestampBound,
  platform = null,
  droppingPercentage = null,
  limit,
  userId,
  authData,
  alertName = null,
  tokenAddress = null,
  peggingPool = null
) {
  try {
    return await sendPostRequest("/api/alerts/price_slippage/get_by_limit", {
      endTimestamp,
      startTimestampBound,
      platform,
      droppingPercentage,
      limit,
      alertName,
      tokenAddress,
      peggingPool,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getPriceSlippageByIds(ids, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/price_slippage/get", {
      ids,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getTestData(id, userId, authData) {
  try {
    return await sendPostRequest("/api/demo/get", {
      id,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function batchGetTestData(ids, userId, authData) {
  try {
    return await sendPostRequest("/api/demo/batchget", {
      ids,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function updateTestData(id, testBlob, userId, authData, expVersion) {
  try {
    return await sendPostRequest("/api/demo/update", {
      id,
      testBlob,
      userId,
      authData,
      expVersion,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function createTestData(testBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/demo/new", {
      userId,
      testBlob,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error || "Create test date failure";
  }
}

export async function validateAcceleratorProjectId(acceleratorProjectId, userId, authData) {
  try {
    return await sendPostRequest("/api/projects/validate_accelerator_project_id", {
      acceleratorProjectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error);
    return;
  }
}

export async function getAcceleratorReportVersions(userId, authData, { acceleratorProjectId }) {
  try {
    return await sendPostRequest("/api/projects/get_accelerator_report_versions", {
      acceleratorProjectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.errMsg;
  }
}

export async function getTenantTrace(traceTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/trace/get_by_tenant_id", {
      traceTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getTenantApiKeys(targetTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/get_by_tenant_id", {
      targetTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getTenantApiKeyUsage({
  userId,
  authData,
  targetTenantId,
  startTimestamp,
  endTimestamp,
}) {
  try {
    return await sendPostRequest("/api/auth/get_tenant_api_key_usage", {
      userId,
      authData,
      deviceId: getDeviceId(),
      targetTenantId,
      startTimestamp,
      endTimestamp,
    });
  } catch (error) {
    return null;
  }
}

export async function createApiKey(apiKeyName, apiKeyTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/new", {
      apiKeyName,
      apiKeyTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error || "Create API Key failure";
  }
}

export async function deleteApiKey(apiKeyId, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/delete", {
      apiKeyId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error || "Delete API Key failure";
  }
}

export async function updateApiKey(apiKeyId, updateData, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/update", {
      apiKeyId,
      updateData,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error || "Update API Key failure";
  }
}

export async function createCentralizationMitigation(
  projectId,
  reportFileName,
  findingId,
  mitigationMeta,
  userId,
  authData
) {
  try {
    const resp = await sendPostRequest("/api/centralization_mitigation/new", {
      projectId,
      reportFileName,
      findingId,
      mitigationMeta,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
    if (resp.result === "success") {
      return true;
    } else {
      return resp;
    }
  } catch (error) {
    let errorMessage = error.error;
    if (error.errMsg) {
      errorMessage += ` - ${error.errMsg}`;
    }
    return errorMessage;
  }
}

export async function createCentralizationMitigationForRemediation(
  mitigationMeta,
  userId,
  authData
) {
  try {
    const resp = await sendPostRequest("/api/centralization_mitigation/new_for_remediation", {
      mitigationMeta,
      userId,
      authData,
      deviceId: getDeviceId(),
    });

    return resp;
  } catch (error) {
    let errorMessage = error.error;
    if (error.errMsg) {
      errorMessage += ` - ${error.errMsg}`;
    }
    return errorMessage;
  }
}

export async function updateCentralizationMitigationMapping(
  findingCombinationKey,
  targetMitigation,
  projectId,
  reportFileName,
  findingId,
  userId,
  authData
) {
  try {
    const resp = await sendPostRequest("/api/centralization_mitigation/update_mapping", {
      findingCombinationKey,
      targetMitigation,
      projectId,
      reportFileName,
      findingId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
    if (resp.result === "success") {
      return true;
    } else {
      return resp;
    }
  } catch (error) {
    let errorMessage = error.error;
    if (error.errMsg) {
      errorMessage += ` - ${error.errMsg}`;
    }
    return errorMessage;
  }
}

export async function getAllCentralizationMitigation(userId, authData, projectId) {
  try {
    const resp = await sendPostRequest("/api/centralization_mitigation/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectId,
    });
    return resp;
  } catch (error) {
    return error.error || "get all centralization mitigation of projectId failed.";
  }
}

export async function getFindingCentralizationMitigation(userId, authData, findingCombinationKey) {
  try {
    const resp = await sendPostRequest("/api/centralization_mitigation/get", {
      userId,
      authData,
      deviceId: getDeviceId(),
      findingCombinationKey,
    });
    return resp;
  } catch (error) {
    return error.error || "get centralization mitigation of finding failed.";
  }
}

export async function submitKycRequest(userId, authData, projectId, projectInfo) {
  try {
    return await sendPostRequest("/api/projects/kyc_request", {
      userId,
      authData,
      projectId,
      projectInfo,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error;
  }
}

/**
 * gets the table item in `client-portal-contentful-ENV` for a given `acceleratorProjectId`
 * @param {string} userId
 * @param {string} authData
 * @param {string} acceleratorProjectId accelerator id
 * @returns {Promise<{ success: true, result: { acceleratorProjectId: string; id: string; name: string; sysId: string; }} | { success: false, reason: string };>}
 */
export async function getContentfulInfoByAccId(userId, tenantId, authData, acceleratorProjectId) {
  try {
    const contentfulInfo = await sendPostRequest("/api/contentful/get", {
      acceleratorProjectId,
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
    if (contentfulInfo == null) {
      throw { errMsg: "not found" };
    }
    if (contentfulInfo.errMsg) {
      throw contentfulInfo;
    }
    return {
      success: true,
      result: contentfulInfo,
    };
  } catch (error) {
    return {
      success: false,
      reason: error.errMsg,
    };
  }
}

export async function getAllWashTradingAlerts(userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/wash_trading/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return [];
  }
}

export async function getAllTornadoCashAlerts(userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/tornado_cash/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return [];
  }
}

export async function getSearchResultByAddress(address, userId, authData) {
  try {
    return await sendPostRequest("/api/analytics/get_search_result", {
      address,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return [];
  }
}

export async function getSwaggerJson(userId, authData) {
  try {
    return await sendPostRequest("/api/swagger_doc", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (ce) {
    return null;
  }
}

/**
 *
 * @param {string} userId
 * @param {any} authData
 * @param {string} projectId audit project id
 * @returns
 */
export async function getIncrementalFindingsReport(userId, authData, projectId) {
  try {
    return await sendPostRequest(`/api/incremental_findings_report/get`, {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectId: projectId,
    });
  } catch (error) {
    return {
      success: false,
      reason: error.errMsg,
    };
  }
}

export async function getAllIncrementalFindings(projectId, userId, authData) {
  try {
    return await sendPostRequest(`/api/incremental_findings_report/get_all`, {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return [];
  }
}

export async function getIncrementalFindingHistory(projectId, findingFid, userId, authData) {
  try {
    return await sendPostRequest(`/api/incremental_findings_report/get_finding_history`, {
      projectId,
      findingFid,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return [];
  }
}

export async function getIncrementalFindingsReportForServer(userId, authData, projectId) {
  const requestUrlPrefix =
    process.env.NEXT_PUBLIC_ENV == "development"
      ? process.env.NEXT_PUBLIC_DOMAIN
      : "https://" + process.env.NEXT_PUBLIC_VERCEL_URL;
  try {
    return await sendPostRequestFromServer(
      requestUrlPrefix + "/api/incremental_findings_report/get",
      {
        userId,
        authData,
        deviceId: authData.linkId ? "" : getDeviceId(),
        projectId,
      },
      undefined,
      undefined,
      false
    );
  } catch (error) {
    return {
      success: false,
      reason: error.errMsg,
    };
  }
}

export async function getIncrementalFindingsByPeriod(
  startTimestamp,
  endTimestamp,
  userId,
  authData
) {
  try {
    return await sendPostRequest(`/api/incremental_findings_report/get_by_period`, {
      startTimestamp,
      endTimestamp,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function deleteSlackAuth(userId, authData) {
  try {
    return await sendPostRequest("/api/oauth/slack_auth_delete", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Delete slack auth failed.";
  }
}

export async function deleteDiscordAuth(userId, authData) {
  try {
    return await sendPostRequest("/api/oauth/discord_auth_delete", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Delete discord auth failed.";
  }
}

/**
 *
 * @param {string} provider | 'slack', 'discord' etc.
 * @param {string} type | 'user' or 'tenant'
 * @param {string} userId
 * @param {string} tenantId
 * @param {*} authData
 * @returns
 */
export async function getOauthUrl(provider, type, userId, tenantId, authData) {
  try {
    return await sendPostRequest("/api/oauth/get_oauth_url", {
      authData,
      provider,
      type,
      userId,
      oauthTenantId: tenantId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get auth url failed.";
  }
}

export async function getDiscordChannelList(type, userId, oauthTenantId, authData) {
  try {
    return await sendPostRequest("/api/oauth/get_discord_channel_list", {
      provider: "discord",
      type,
      userId,
      oauthTenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get discord channel list failed.";
  }
}

export async function getSlackChannelList(type, userId, oauthTenantId, authData) {
  try {
    return await sendPostRequest("/api/oauth/get_slack_channel_list", {
      provider: "slack",
      type,
      userId,
      oauthTenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get slack channel list failed.";
  }
}

export async function getTenantNotificationCustomization(userId, tenantId, authData) {
  try {
    return await sendPostRequest("/api/notifications/get_customization", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get Notification Customization Failed.";
  }
}

export async function updateTenantNotificationCustomization(
  userId,
  tenantId,
  notificationModule,
  enabledNotificationType,
  channel,
  expVersion,
  authData
) {
  try {
    return await sendPostRequest("/api/notifications/update_customization", {
      userId,
      tenantId,
      notificationModule,
      enabledNotificationType,
      channel,
      expVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update Notification Customization Failed.";
  }
}

export async function createSlackInteralChannelForTenant(
  userId,
  tenantId,
  slackTenantId,
  channelBlob,
  authData
) {
  try {
    return await sendPostRequest("/api/slack/new_internal_channel", {
      userId,
      tenantId,
      slackTenantId,
      channelBlob,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Create Slack Internal Channel Failed.";
  }
}

export async function createTelegramInteralChannelForTenant(
  userId,
  tenantId,
  telegramTenantId,
  channelBlob,
  channelConfig,
  authData
) {
  try {
    return await sendPostRequest("/api/telegram/new_internal_channel", {
      userId,
      tenantId,
      telegramTenantId,
      channelBlob,
      channelConfig,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Create telegram Internal Channel Failed.";
  }
}

export async function postClientRequestToAccelerator(userId, authData, requestToAccString, accId) {
  try {
    return await sendPostRequest("/api/accelerator/clientrequest", {
      userId,
      authData,
      requestToAccString,
      accId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Post Client Request To Accelerator Failed.";
  }
}

export async function getAddendumProjectInfos(userId, authData, projectId) {
  try {
    return await sendPostRequest("/api/projects/get_addendum_info", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectId: projectId,
    });
  } catch (error) {
    return error.error || "Get Addendum Project Failed";
  }
}

export async function fetchSkynetSignalCommentList(
  signalCombinationKey,
  projectId,
  userId,
  authData,
  limit,
  paginationTimestamp,
  entryType
) {
  try {
    return await sendPostRequest("/api/skynets/signals/comments/get_all", {
      signalCombinationKey,
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
      limit,
      paginationTimestamp,
      entryType,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function postNewSkynetSignalComment(
  signalCombinationKey,
  projectId,
  userId,
  authData,
  text,
  images,
  emailList,
  entryType
) {
  try {
    return await sendPostRequest("/api/skynets/signals/comments/new", {
      signalCombinationKey,
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
      text,
      images,
      emailList,
      entryType,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function fetchSkynetSignalRemediation(
  userId,
  authData,
  projectId,
  signalId,
  entryType
) {
  try {
    const resp = await sendPostRequest("/api/skynets/signals/remediations/get", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectId,
      signalId,
      entryType,
    });
    return resp;
  } catch (error) {
    return error.error || "get skynet signal remediation of projectId failed.";
  }
}

export async function fetchSkynetSignalRemediationsByProjectId(userId, authData, projectId) {
  try {
    const resp = await sendPostRequest("/api/skynets/signals/remediations/get_all", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectId,
    });
    return resp;
  } catch (error) {
    return error.error || "get all skynet signal remediations of projectId failed.";
  }
}

export async function createOrNewSkynetSignalRemediation(
  userId,
  authData,
  projectId,
  signalId,
  remediationMeta,
  entryType,
  issueType
) {
  try {
    const resp = await sendPostRequest("/api/skynets/signals/remediations/new", {
      userId,
      authData,
      deviceId: getDeviceId(),
      projectId,
      signalId,
      remediationMeta,
      entryType,
      issueType,
    });
    return resp;
  } catch (error) {
    return error.error || "create new skynet signal remediation failed.";
  }
}

export async function getSkynetSignalHistory(
  userId,
  projectId,
  signalId,
  limit,
  paginationTimestamp,
  authData,
  entryType
) {
  try {
    const resp = await sendPostRequest("/api/skynets/signals/history/get_all", {
      projectId,
      signalId,
      limit,
      paginationTimestamp,
      userId,
      authData,
      deviceId: getDeviceId(),
      entryType,
    });
    return resp;
  } catch (error) {
    return error.error || "get all skynet signal remediations of projectId failed.";
  }
}

export async function getSkynetAuditReports(skynetProjectId, userId, authData) {
  try {
    return await sendPostRequest(`/api/skynets/${skynetProjectId}/get_audit_report`, {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error?.message);
    return [];
  }
}

export async function notifySkynetProjectBD(skynetProjectId, userId, authData) {
  try {
    return await sendPostRequest(`/api/skynets/hubspot/send_noti_to_bd`, {
      skynetProjectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.error(error?.message);
    return [];
  }
}

export async function getSkynetProjectFromLeaderboard(id, userId, authData) {
  try {
    const resp = await sendPostRequest("/api/skynets/get_from_leaderboard", {
      id,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
    return resp;
  } catch (error) {
    return error.error || "Failed to get skynet from leaderboard";
  }
}

export async function getMarketplaceUserInterest(userId, authData, filterGroup) {
  try {
    const resp = await sendPostRequest("/api/marketplace/get_user_interest_project", {
      userId,
      authData,
      filterGroup,
      deviceId: getDeviceId(),
    });
    return resp;
  } catch (error) {
    return error.error || "Failed to get user interest data";
  }
}

export async function getMarketplaceUserInterestForTenant(userId, forTenant, authData) {
  try {
    const resp = await sendPostRequest("/api/marketplace/get_user_interest_project_for_tenant", {
      userId,
      forTenant,
      authData,
      deviceId: getDeviceId(),
    });
    return resp;
  } catch (error) {
    return [];
  }
}

export async function createMarketplaceUserInterest(userId, authData, projectId) {
  try {
    const resp = await sendPostRequest("/api/marketplace/create_user_interest", {
      userId,
      authData,
      projectId,
      deviceId: getDeviceId(),
    });
    return resp;
  } catch (error) {
    return error.error || "Failed to create user interest data";
  }
}

export async function deleteMarketplaceUserInterest(userId, authData, userActionId, projectId) {
  try {
    const resp = await sendPostRequest("/api/marketplace/delete_user_interest", {
      userId,
      authData,
      userActionId,
      projectId,
      deviceId: getDeviceId(),
    });
    return resp;
  } catch (error) {
    return error.error || "Failed to create user interest data";
  }
}

export async function getProjectNameAndImages(params) {
  try {
    const requestUrlPrefix =
      process.env.NEXT_PUBLIC_ENV == "development"
        ? process.env.NEXT_PUBLIC_DOMAIN
        : "https://" + process.env.NEXT_PUBLIC_VERCEL_URL;
    return await sendPostRequestFromServer(
      requestUrlPrefix + "/api/projects/get_project_name_and_images",
      params,
      undefined,
      undefined,
      false
    );
  } catch (error) {
    return {};
  }
}

export async function getTelegramGroupLink(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_skynet", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getMarketplaceProject(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/projects/get_marketplace_project", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getMarketplaceProjectInterest(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/marketplace/get_project_interest", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function getMarketplaceProjectInterestHistory(
  projectId,
  paginationTimestamp,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/marketplace/history/get_all", {
      projectId,
      paginationTimestamp,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

// @deprecated use getGeneralTelegramGroupForTenant
export async function getTelegramGroupForTenant(userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_tenant", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getGeneralTelegramGroupForTenant(userId, authData, tenantId) {
  try {
    return await sendPostRequest("/api/telegram/get_general_telegram_group_for_tenant", {
      userId,
      authData,
      tenantId,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function fetchUserActionsByProjectId(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/users/get_all_user_actions_by_projectId", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function fetchUserActionsByTenantId(actionTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/users/get_all_user_actions_by_tenantId", {
      actionTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function fetchUserActionsByUserId(userId, authData, limit, lastId) {
  try {
    return await sendPostRequest("/api/users/get_user_actions_by_userId", {
      userId,
      authData,
      deviceId: getDeviceId(),
      limit,
      lastId,
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function getMarketplaceAssigneesByProjectId(projectId, role, userId, authData) {
  try {
    return await sendPostRequest("/api/marketplace_assignee/get_by_project_id", {
      projectId,
      role,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function updateMarketplaceAssignee(
  newAssigneeId,
  oldAssigneeId,
  projectId,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/marketplace_assignee/update", {
      newAssigneeId,
      oldAssigneeId,
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function checkIpWhitelist() {
  try {
    return await sendGetRequest("/api/configs/check_ip");
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getTelegramGroupForAuditProject(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_audit_project", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function createTelegramGroupForSkynetTrustScore(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/create_telegram_group_for_skynet_trust_score", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getTelegramGroupForSkynetTrustScore(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_skynet_trust_score", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getAllEvidenceByAlienKey(alienKey, userId, authData) {
  try {
    return await sendPostRequest("/api/evidence/get_all_by_alien_key", {
      alienKey,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function checkUserSession(userId, authData) {
  try {
    return await sendPostRequest("/api/users/check_session_status", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getEnvConfig(key, env, userId, authData) {
  try {
    return await sendPostRequest("/api/envs/getMeta", {
      key,
      env,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function createOrUpdateFindingFeedback(authData, userId, feedback) {
  try {
    return await sendPostRequest("/api/findings/feedback/create_or_update", {
      feedback,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getFindingFeedbackByReportId(authData, userId, reportId) {
  try {
    return await sendPostRequest("/api/findings/feedback/get_by_report_id", {
      reportId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function createAuditOverviewProgressReport(reportBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/progress_reports/new", {
      reportBlob,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function deleteAuditOverviewProgressReports(deletedIds, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/progress_reports/delete", {
      deletedIds,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function updateAuditOverviewProgressReport(reportBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/progress_reports/update", {
      reportBlob,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getWeeklyAuditOverviewProgressReports(
  companyTenantId,
  anchorTimestamp,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/audit_overview/progress_reports/get_weekly_reports", {
      companyTenantId,
      anchorTimestamp,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getLatestAuditOverviewProgressReports(companyTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/progress_reports/get_latest", {
      companyTenantId,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function createAuditOverviewFindingsReport(reportBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/findings_reports/new", {
      reportBlob,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function deleteAuditOverviewFindingsReports(deletedIds, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/findings_reports/delete", {
      deletedIds,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function updateAuditOverviewFindingsReport(reportBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/findings_reports/update", {
      reportBlob,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getWeeklyAuditOverviewFindingsReports(
  companyTenantId,
  anchorTimestamp,
  userId,
  authData
) {
  try {
    return await sendPostRequest("/api/audit_overview/findings_reports/get_weekly_reports", {
      companyTenantId,
      anchorTimestamp,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getLatestAuditOverviewFindingsReports(companyTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/audit_overview/findings_reports/get_latest", {
      companyTenantId,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getAuditProjectsByPeriod(timeRange, userId, authData) {
  try {
    return await sendPostRequest("/api/projects/get_by_period", {
      timeRange,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function inviteCertiKBotToInternalChannel(channelId, userId, authData) {
  try {
    return await sendPostRequest("/api/oauth/invite_bot_to_certik_channel", {
      channelId,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getEmblemPreviewCode(
  userId,
  authData,
  projectId,
  emblemDesign = "card",
  emblemTheme = "light"
) {
  try {
    return await sendPostRequest(
      `/api/cross_sale/get_emblem_preview_code`,
      {
        userId,
        projectId,
        authData,
        emblemDesign,
        emblemTheme,
        deviceId: getDeviceId(),
      },
      {},
      null,
      true
    );
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function requestEmblem({ projectId, userId, tenantId, projectVersion, authData }) {
  try {
    return await sendPostRequest(`/api/cross_sale/request_emblem`, {
      projectId,
      userId,
      tenantId,
      projectVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function postMeetingRequestForBadges(projectId, userId, authData, blob) {
  try {
    return await sendPostRequest("/api/skynets/new_request_meeting", {
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
      blob,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMeetingRequestForBadges(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/skynets/get_request_meeting", {
      projectId,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAddresses(chainAddresses, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_many", {
      chainAddresses,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getPlansByTenantId({ tenantId, userId, authData, targetTenantId }) {
  try {
    return await sendPostRequest("/api/plan/get_all", {
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
      targetTenantId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getUserPlans({ tenantId, userId, authData }) {
  try {
    return await sendPostRequest("/api/tenants/get_user_plans", {
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function createTenantPlan({
  userId,
  authData,
  tenantId,
  targetTenantId,
  templateId,
  startTimestamp,
  metadata,
}) {
  try {
    return await sendPostRequest("/api/plan/new", {
      userId,
      tenantId,
      authData,
      targetTenantId,
      templateId,
      metadata,
      startTimestamp,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updatePlan({
  userId,
  authData,
  tenantId,
  planId,
  templateId,
  startTimestamp,
  metadata,
}) {
  try {
    return await sendPostRequest("/api/plan/update", {
      userId,
      tenantId,
      authData,
      planId,
      templateId,
      metadata,
      startTimestamp,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function stopPlan({ userId, authData, tenantId, planId }) {
  try {
    return await sendPostRequest("/api/plan/stop", {
      userId,
      tenantId,
      authData,
      planId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**********************
 * STRIPE-RELATED APIS
 *********************/

export async function createCustomerPortalSession(userId, authData) {
  try {
    return await sendPostRequest("/api/app_hooks/stripe/create_customer_portal_session", {
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getTenantStripeInfo(userId, authData) {
  try {
    return await sendPostRequest("/api/app_hooks/stripe/get_tenant_stripe_info", {
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

// Optional subscription field
export async function getInvoicesByTenant(userId, authData, subscriptionId = undefined) {
  try {
    return await sendPostRequest("/api/app_hooks/stripe/get_invoices_by_tenant_id", {
      userId,
      deviceId: getDeviceId(),
      subscriptionId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return { upcoming: [], others: [] };
  }
}

export async function getReferralProjects({ userId, authData }) {
  try {
    return await sendPostRequest("/api/projects/get_referral_projects", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}
